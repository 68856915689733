import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import { motion } from "framer-motion";
import { yerbaText, peppText } from "./data";

import {
  PageContainer,
  BackgroundSectionContainer,
  ItemContainer,
  TextContainer,
  ImageWrapper,
  SecondItemContainer,
  SecondImageWrapper,
  SecondTextContainer,
} from "./BackgroundSection.styles";

const BackgroundSection = ({ mode }) => {
  const [postData, setPostData] = useState("");

  useEffect(() => {
    sanityClient
      .fetch(
        ` *[_type == "project"]{
          backgroundMockupOne{
                     asset->{
                         _id,
                         url
                     },
                 },
          backgroundMockupTwo{
                     asset->{
                         _id,
                         url
                     },
                 }
         }`
      )
     .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  let yerbaMockupOne = postData[0]?.backgroundMockupOne.asset.url;
  let yerbaMockupTwo = postData[0]?.backgroundMockupTwo.asset.url;

  let peppMockupOne = postData[1]?.backgroundMockupOne.asset.url;
  let peppMockupTwo = postData[1]?.backgroundMockupTwo.asset.url;
  

  return (
      <BackgroundSectionContainer mode={mode}>
    <PageContainer mode={mode}>
        <ItemContainer>
          <TextContainer
            as={motion.div}
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            viewport={{ margin: "-160px", once: true  }}
          >
            <h1>
              back
              <br />
              ground<span>.</span>
            </h1>
            <p>{mode === "pepp" ? peppText.bodyOne : yerbaText.bodyOne}</p>
          </TextContainer>
          <ImageWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                type: "spring",
                duration: 0.6,
                delay:0.4
              },
            }}
            viewport={{ margin: "-160px", once: true  }}
          >
            {mode === "pepp" ? (
              <img
                src={peppMockupOne}
                alt="mobile phone mockup showing splash screen and brand logo"
              />
            ) : (
              <img
                src={yerbaMockupOne}
                alt="mobile phone mockup showing splash screen and brand logo"
              />
            )}
          </ImageWrapper>
        </ItemContainer>
        <SecondItemContainer>
          <SecondTextContainer
            as={motion.div}
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            viewport={{ margin: "-160px", once: true  }}
          >
            <h2>
              {mode === "pepp"
                ? peppText.subHeadingOne
                : yerbaText.subHeadingOne}
            </h2>
            <p>{mode === "pepp" ? peppText.bodyTwo : yerbaText.bodyTwo}</p>
          </SecondTextContainer>
          <SecondImageWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0,
              transition: {
                type: "spring",
                duration: 0.6,
                delay:0.4
              }}}
            viewport={{ margin: "-160px", once: true  }}
          >
            {mode === "pepp" ? (
              <img
                src={peppMockupTwo}
                alt="mobile phone mockup showing alternative splash screen and brand logo"
              />
            ) : (
              <img
                src={yerbaMockupTwo}
                alt="mobile phone mockup showing alternative splash screen and brand logo"
              />
            )}
          </SecondImageWrapper>
        </SecondItemContainer>
    </PageContainer>
      </BackgroundSectionContainer>
  );
};

export default BackgroundSection;
