export const yerbaText = {
    subHeading:'Traditional, organic and fresh',
    subHeadingTwo:'Hints of lemon and grass',
    subHeadingThree:'Choosing a typeface',
    bodyOne: 'Drawing inspiration from classic Argentinean posters of yerba mate, the shapes are organic and the colors natural. The rounded shapes make for a friendlier feel, that is also associated with the traditional yerba mate mug – la calabaza.',
    bodyTwo: 'The yellow and green derive from lemon, a common added flavor, as well as the bitter, grassy taste of the drink. The most well-known yerba mate brand with the popular red packages stands in bright contrast to the main colors.',
    bodyThree: 'Londrina solid and Nunito create a contrast where the first is inspired from the artisanal tradition with solid but uneven letters, and the latter gives a more modern and symmetric, yet still rounded and friendly feel.'
}

export const peppText = {
    subHeading:'Inclusive, relaxed and energetic',
    subHeadingTwo:'Playful yet sincere',
    subHeadingThree:'Being well known is not always bad',
    bodyOne: 'Images used in the design should feel natural, relaxed and relatable with regular people working out, rather than posing professional models. The logotype is created using only the Montserrat typeface in bright and uplifting orange. Buttons are rounded for a friendlier feel.',
    bodyTwo: 'The energetic orange meets the rational darker blue to create a design promoting playfulness and movement but that still feels stable and sincere.',
    bodyThree: 'The main typeface used is the immensely popular Montserrat. Montserrat with its clean geometric shape creates symmetry and stability while the other rounded and easily legible typeface Nunito adds a friendlier feel. '
}