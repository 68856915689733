import sanityClient from '@sanity/client'


export default sanityClient({
dataset: process.env.REACT_APP_SANITY_DATASET || "production",
projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
apiVersion: "2022-05-01",
useCdn: process.env.NODE_ENV === "production",
})

