export const projectPageObj = {
  pageHeading: "projects",
  span: ".",
};

export const projectArr = [
  {
    name:"El Mate Dorado",
    projectTopline: "UX, UI, Bradning, Front-end",
    projectHeading: "El Mate Dorado",
    projectSubHeading: "- yerba mate specialists",
    projectBtnText: "view project",
  },
  {
    name:"Pepp & Svett",
    projectTopline: "UX, UI, Bradning, Front-end",
    projectHeading: "Pepp & Svett",
    projectSubHeading: "- a gym for everyone",
    projectBtnText: "view project",
  },
];
