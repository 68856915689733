import { FaBehance } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";
import { headerObj } from "./Data";
import { motion } from "framer-motion";

import {
  HeaderSectionContainer,
  MainHeading,
  SubHeading,
  PageContainer,
  ContactItem,
  ContactLink,
  Icons,
} from "./HeaderSection.styles";

const HeaderSection = () => {
  
  return (
    <>
      <HeaderSectionContainer>
        <PageContainer>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              y: -20
            }}
            // transition={{ duration:0.3}}
          >
            <MainHeading>
              {headerObj.firstLineHeading}
              <br />
              {headerObj.secondLineHeading}
              <br />
              {headerObj.thirdLineHeading}
              <span>{headerObj.span}</span>
            </MainHeading>
          </motion.div>
          <motion.div 
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            y: -20
          }}
          transition={{ delay: 0.2 }}
          >
          <SubHeading>{headerObj.subHeading}</SubHeading>
          </motion.div>

          <Icons
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <a
              href="https://www.behance.net/yofal"
              target="_blank"
              rel="noreferrer"
              aria-label="Behance"
            >
              <FaBehance />
            </a>
            <a
              href="https://github.com/yofal"
              target="_blank"
              rel="noreferrer"
              aria-label="Github"
            >
              <AiFillGithub />
            </a>
          </Icons>

          <ContactItem
            as={motion.p}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          ></ContactItem>
          <ContactLink to="/">{headerObj.contact}</ContactLink>
        </PageContainer>
      </HeaderSectionContainer>
    </>
  );
};

export default HeaderSection;
