import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { Link } from "react-router-dom";
import { breakpoints } from "../../Themes";

export const Nav = styled.nav`
  background: ${({ theme }) => theme.navBarBg};
  height: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  ${breakpoints.s} {
    height: 4em;
  }

  ${breakpoints.tablet} {
    height: 6em;
  }
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6em;

  ${breakpoints.s} {
    height: 4em;
    padding-right: 1em;
    padding-left: 1em;
  }

  ${breakpoints.tablet} {
    padding-right: 2em;
    padding-left: 2em;
  }

  ${breakpoints.laptop} {
    padding-right: 4em;
    padding-left: 4em;
  }

  ${Container}
`;

// yofal svg
export const NavLogo = styled(Link)`
  cursor: pointer;
  user-select:none;

  ${breakpoints.s} {
    margin: 0 auto;
    padding-left: 2.2em;
  }

  ${breakpoints.laptop} {
    margin: 0;
    padding-left: 0;
  }
`;

export const LogoWrapper = styled.div`
  img {
    object-fit: contain;

    ${breakpoints.s} {
      width: 80px;
  }

  ${breakpoints.tablet} {
      width: 100px;
  }
  }
`;

export const MobileIcon = styled.div`
  ${breakpoints.s} {
    display: block;
    padding-top: 0.2em;
    font-size: 1.8rem;
    cursor: pointer;
    user-select: none;
  }
  

  ${breakpoints.laptop} {
    display: none;
  }
`;

export const NavMenu = styled.ul`
  list-style: none;
  display: flex;

  ${breakpoints.s} {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    flex-direction: column;
    
    width: 100%;
    min-height: 100vh;
    position: absolute;
    
    background: ${({ theme }) => theme.primary};
   
    top: 4rem;
    padding-top: 4rem;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.3s ease;
  }

  ${breakpoints.m} {
    padding-top:4rem;
  }
  ${breakpoints.l} {
    padding-top:4rem;
  }

  ${breakpoints.tablet} {
    top: 5.9rem;
    padding-top: 8rem;
  }

  ${breakpoints.laptop} {
    all: initial;
    list-style: none;
    flex-direction: row;
    display: flex;
  }
`;
export const NavItem = styled.li`
  cursor: pointer;
`;
export const NavLinks = styled(Link)`
  color: ${({ theme }) => theme.textWhite};
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.primaryDarker};
    transition: all 0.3s ease;
  }
  &:active {
    color: ${({ theme }) => theme.linkActive};
    transition: all 0.3s ease;
  }

  ${breakpoints.s} {
    text-align: center;
    width: 100%;
    font-weight: 900;
    font-size: 2rem;
    letter-spacing: -0.06em;
    padding-bottom:1rem;
    
  }

  ${breakpoints.m} {
    font-size:3.2rem;
  }

  ${breakpoints.tablet} {
    font-size: 3.5rem;
  }

  ${breakpoints.laptop} {
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 1rem;
    height: 100%;
    padding: 1.5rem;
  }

`;

export const TogglerWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  user-select: none;
  z-index: 1000;
  align-self: center;
  cursor: pointer;
  
  ${breakpoints.s} {
    font-size: 3rem;
    margin-top: 1rem;
  }

  ${breakpoints.laptop} {
    font-size: 1.8rem;
    margin:0;
    padding-left:1rem;
  }

`;
