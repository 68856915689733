import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import { motion } from "framer-motion";
import { yerbaText, peppText } from "./data";

import {
  PageContainer,
  UISectionContainer,
  ItemContainer,
  TextContainer,
  ImageWrapper,
  SecondItemContainer,
  SecondImageWrapper,
  SecondTextContainer,
  ThirdItemContainer,
  ThirdImageWrapper,
  ThirdTextContainer,
} from "./UISection.styles";

const UISection = ({ mode }) => {
  const [postData, setPostData] = useState("");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type  == "project"]{
          logoImg{
              asset->{
                  _id,
                  url
              },
          },
   backgroundMockupOne{
              asset->{
                  _id,
                  url
              },
          },
   backgroundMockupTwo{
              asset->{
                  _id,
                  url
              },
          },
   uiMockupOne{
              asset->{
                  _id,
                  url
              },
          },
   uiMockupTwo{
              asset->{
                  _id,
                  url
              },
          },
   uiMockupThree{
              asset->{
                  _id,
                  url
              },
          },
  }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);

  let yerbaMockupOne = postData[0]?.uiMockupOne.asset.url;
  let yerbaMockupTwo = postData[0]?.uiMockupTwo.asset.url;
  let yerbaMockupThree = postData[0]?.uiMockupThree.asset.url;

  let peppMockupOne = postData[1]?.uiMockupOne.asset.url;
  let peppMockupTwo = postData[1]?.uiMockupTwo.asset.url;
  let peppMockupThree = postData[1]?.uiMockupThree.asset.url;

  return (
      <UISectionContainer mode={mode}>
    <PageContainer mode={mode}>
        <ItemContainer>
          <TextContainer
            as={motion.div}
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            viewport={{ margin: "-160px", once: true  }}
          >
            <h1>
              UI<span>.</span>
            </h1>
            <p>
              {mode === "pepp" ? peppText.subHeading : yerbaText.subHeading}
            </p>
            <p>{mode === "pepp" ? peppText.bodyOne : yerbaText.bodyOne}</p>
          </TextContainer>
          <ImageWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                type: "spring",
                duration: 0.6,
                delay: 0.4,
              },
            }}
            viewport={{ margin: "-160px", once: true  }}
            mode={mode}
          >
            {mode === "pepp" ? (
              <img
                src={peppMockupOne}
                alt="mobile phone mockup showing front page"
              />
            ) : (
              <img
                src={yerbaMockupOne}
                alt="mobile phone mockup showing front page"
              />
            )}
          </ImageWrapper>
        </ItemContainer>
        <SecondItemContainer>
          <SecondTextContainer
            as={motion.div}
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            viewport={{ margin: "-160px", once: true  }}
          >
            <p>
              {mode === "pepp"
                ? peppText.subHeadingTwo
                : yerbaText.subHeadingTwo}
            </p>
            <p>{mode === "pepp" ? peppText.bodyTwo : yerbaText.bodyTwo}</p>
          </SecondTextContainer>
          <SecondImageWrapper
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                type: "spring",
                duration: 0.6,
                delay: 0.4,
              },
            }}
            viewport={{ margin: "-160px", once: true  }}
            mode={mode}
          >
            {mode === "pepp" ? (
              <img src={peppMockupTwo} alt="tablet mockup showing price page" />
            ) : (
              <img
                src={yerbaMockupTwo}
                alt="tablet mockup showing product page"
              />
            )}
          </SecondImageWrapper>
        </SecondItemContainer>

        <ThirdItemContainer>
          <ThirdTextContainer
            as={motion.div}
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            viewport={{ margin: "-160px", once: true  }}
          >
            <p>
              {mode === "pepp"
                ? peppText.subHeadingThree
                : yerbaText.subHeadingThree}
            </p>
            <p>{mode === "pepp" ? peppText.bodyThree : yerbaText.bodyThree}</p>
          </ThirdTextContainer>
          <ThirdImageWrapper
                  as={motion.div}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      type: "spring",
                      duration: 0.3,
                      delay: 0.4,
                    },
                  }}
                  viewport={{ margin: "-160px", once: true  }}
            mode={mode}
          >
            {mode === "pepp" ? (
              <img
                src={peppMockupThree}
                alt="desktop mockup showing front page"
              />
            ) : (
              <img
                src={yerbaMockupThree}
                alt="desktop mockup showing front page"
              />
            )}
          </ThirdImageWrapper>
        </ThirdItemContainer>
    </PageContainer>
      </UISectionContainer>
  );
};

export default UISection;
