import GlobalStyle from "./globalStyle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components";
import { FirstProject, SecondProject, Home } from "./pages";
import { lightTheme, darkTheme } from "./Themes";
import { ThemeProvider } from "styled-components";
import { ScrollToTop } from "./components";
import { useDarkMode } from "./hooks/useDarkMode";

function App() {
  const [theme, themeToggler, mountedComponent] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!mountedComponent) return <div />;
  return (
    <ThemeProvider theme={themeMode}>
      <Router>
        <ScrollToTop />
        <GlobalStyle />
        <Navbar themeToggler={themeToggler} theme={theme} />
        <Routes>
          <Route index element={<Home theme={theme} />} />
          <Route path="/firstproject" element={<FirstProject />} />
          <Route
            path="/secondproject"
            element={<SecondProject theme={theme} />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
