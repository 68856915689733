import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import projectImg1 from "../../assets/img/project-1.png";
import projectImg1DM from "../../assets/img/project-1-DM.png";
import projectImg2 from "../../assets/img/project-2.png";
import projectImg2DM from "../../assets/img/project-2-DM.png";

import mobileProjectImg1 from "../../assets/img/mobile-project-1.png";
import mobileProjectImg1DM from "../../assets/img/mobile-project-1-DM.png";
import mobileProjectImg2 from "../../assets/img/mobile-project-2.png";
import mobileProjectImg2DM from "../../assets/img/mobile-project-2-DM.png";
import { projectArr, projectPageObj } from "./Data";

import { FaReact } from "react-icons/fa";
import {
  SiTailwindcss,
  SiNextdotjs,
  SiFramer,
  SiStyledcomponents,
} from "react-icons/si";
import { IoLogoFigma } from "react-icons/io5";
import {
  PageContainer,
  SecondProjectImg,
  ProjectImg,
  MobileProjectImg,
  ProjectSectionContainer,
  ProjectItem,
  SecondProjectItem,
  TopLine,
  PageHeading,
  ProjectHeading,
  ProjectSubHeading,
  TextContainer,
  SecondTopLine,
  SecondProjectHeading,
  SecondProjectSubHeading,
  SecondTextContainer,
  ProjectBtn,
  SecondProjectBtn,
  IconContainer,
  SecondIconContainer,
  ProjectLink,
  ProjectItemContainer,
  SecondProjectItemContainer,
} from "./ProjectSection.styles";

const ProjectSection = (props) => {
  const navigate = useNavigate();

  const { theme } = props;

  return (
    <ProjectSectionContainer>
      <PageContainer id="projects">
        <PageHeading
          as={motion.h2}
          initial={{ opacity: 0, y: 15 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ margin: "-150px", once: true  }}
 
        >
          {projectPageObj.pageHeading}
          <span>{projectPageObj.span}</span>
        </PageHeading>
        <ProjectLink onClick={() => navigate("/firstproject")}>
          <ProjectItemContainer>
            <ProjectItem
              as={motion.div}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              {theme === "light" ? (
                <ProjectImg
                  src={projectImg1}
                  alt="tablet mockup showing front page"
                />
              ) : (
                <ProjectImg
                  src={projectImg1DM}
                  alt="tablet mockup in dark mode showing front page"
                />
              )}
              {theme === "light" ? (
                <MobileProjectImg
                  src={mobileProjectImg1}
                  alt="mobile mockup showing front page"
                />
              ) : (
                <MobileProjectImg
                  src={mobileProjectImg1DM}
                  alt="mobile mockup in dark mode showing front page"
                />
              )}
              <TextContainer>
                <TopLine
                  as={motion.p}
                  initial={{ opacity: 0, y: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                 
                >
                  {projectArr[0].projectTopline}
                </TopLine>
                <ProjectHeading
                  as={motion.p}
                  initial={{ opacity: 0, y: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  {projectArr[0].projectHeading}
                </ProjectHeading>
                <ProjectSubHeading
                  as={motion.p}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  {projectArr[0].projectSubHeading}
                </ProjectSubHeading>
                <ProjectBtn
                  as={motion.p}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  {projectArr[0].projectBtnText}
                </ProjectBtn>
                <IconContainer
                  as={motion.p}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  <IoLogoFigma />
                  <SiNextdotjs />
                  <SiTailwindcss />
                  <SiFramer />
                </IconContainer>
              </TextContainer>
            </ProjectItem>
          </ProjectItemContainer>
        </ProjectLink>
        <ProjectLink onClick={() => navigate("/secondproject")}>
          <SecondProjectItemContainer>
            <SecondProjectItem
              as={motion.div}
              initial={{ opacity: 0, y: 15 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ margin: "-150px", once: true  }}
            >
              {theme === "light" ? (
                <SecondProjectImg
                  src={projectImg2}
                  alt="tablet mockup showing front page"
                />
              ) : (
                <SecondProjectImg
                  src={projectImg2DM}
                  alt="tablet mockup in dark mode showing front page"
                />
              )}
              {theme === "light" ? (
                <MobileProjectImg
                  src={mobileProjectImg2}
                  alt="mobile mockup showing front page"
                />
              ) : (
                <MobileProjectImg
                  src={mobileProjectImg2DM}
                  alt="mobile mockup in dark mode showing front page"
                />
              )}
              <SecondTextContainer>
                <SecondTopLine
                  as={motion.p}
                  initial={{ opacity: 0, y: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                
                >
                  {projectArr[1].projectTopline}
                </SecondTopLine>
                <SecondProjectHeading
                  as={motion.p}
                  initial={{ opacity: 0, y: 0 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  {projectArr[1].projectHeading}
                </SecondProjectHeading>
                <SecondProjectSubHeading
                  as={motion.p}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.2  }}
                  viewport={{ once: true  }}
                  
                >
                  {projectArr[1].projectSubHeading}
                </SecondProjectSubHeading>
                <SecondProjectBtn
                  as={motion.p}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  {projectArr[1].projectBtnText}
                </SecondProjectBtn>
                <SecondIconContainer
                  as={motion.p}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.2 }}
                  viewport={{ once: true  }}
                  
                >
                  <IoLogoFigma />
                  <FaReact />
                  <SiStyledcomponents />
                  <SiFramer />
                </SecondIconContainer>
              </SecondTextContainer>
            </SecondProjectItem>
          </SecondProjectItemContainer>
        </ProjectLink>
      </PageContainer>
    </ProjectSectionContainer>
  );
};

export default ProjectSection;
