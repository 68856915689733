import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const PageContainer = styled(Container)`
padding:0;
display: flex;
  flex-direction: column;
  justify-content:center;
  ${Container}


  

`;

export const TechSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content:center;
  padding-top:2rem;

  background: ${({ theme }) => theme.primary};
  width: 100%;
  padding-bottom:4rem;
    ${breakpoints.tablet} {
    height:100vh;

  }

`;

export const PageHeading = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  letter-spacing: -0.06em;
  text-align: center;
 
  padding-bottom: 2rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  span{
    color: ${({ theme }) => theme.accent};
  }

  ${breakpoints.s} {
    font-size: 3rem;
    padding-top:4rem;

  }
  ${breakpoints.m} {

  }
  ${breakpoints.l} {
    padding-top:5rem;


  }
  ${breakpoints.tablet} {
    font-size: 3.5rem;
    padding-top:5rem;

  }

  ${breakpoints.laptop} {
    font-size: 3rem;
    margin-top:-8rem;
  }

  ${breakpoints.desktop} {
    font-size: 5rem;
    padding-bottom:5rem;
  }


`;

export const IconContainer = styled.div`
  display: grid;

  color: ${({ theme }) => theme.textWhite};
  justify-items: center;
  
  margin: 0 auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @media screen and (max-width: 1280px) {
 
 }


  ${breakpoints.s} {
    font-size: 2.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }

  ${breakpoints.m} {
    font-size: 3rem;
  }

  ${breakpoints.l} {
    
  }

  ${breakpoints.tablet} {
    grid-template-columns: repeat(6, 1fr);
    font-size: 2.5rem;
    grid-gap: 2em;
  }

  ${breakpoints.laptop} {
    grid-template-columns: repeat(6, 1fr);
    font-size: 3.2rem;
    margin-top:2rem;
  }
  ${breakpoints.desktop} {
    grid-gap: 2.8em;
    font-size: 3.5rem;
    margin-bottom:-8rem;
    margin-top:0rem;
  }
`;
