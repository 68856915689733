import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";


export const FontSectionContainer = styled.div` 
background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgOrange : props.theme.yerbaYellow)}; 
color: ${( props ) => (props.mode === 'pepp' ? props.theme.peppTextWhite : props.theme.textBlack)}; 
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* padding-bottom:5rem; */

  
  ${breakpoints.s} {


  }

  ${breakpoints.m} {
    
  }
  /* ${breakpoints.l} {
    padding-top:3rem;
    height:160vh;
  }
  ${breakpoints.tablet} {
    padding-top:0;
    padding-bottom:10rem;
    height:120vh;
  }
  ${breakpoints.laptop} {
    padding-top:8rem;
    padding-bottom:10rem;
    height:150vh;
  }
  ${breakpoints.desktop} {
    padding-top:0rem;
    padding-bottom:0rem;
    height:100vh;
  } */


`
export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.textBlack};
  padding-bottom:2rem;
  ${Container}
`;

export const Heading = styled.h1`
  font-weight: 900;
  text-align: center;
  font-size: 6rem;
  span {
    color: ${({ theme }) => theme.textWhite};
  }

  ${breakpoints.s} {
    font-size: 4rem;
    padding-top: 0rem;
  }

  ${breakpoints.m} {
  }
  ${breakpoints.l} {
  }

  ${breakpoints.laptop} {
    font-size: 6rem;
  }
`;

export const FontOne = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  text-align: center;
  font-size: 6rem;

  ${breakpoints.s} {
    font-size: 3rem;
  }
  padding-top: 2rem;

  ${breakpoints.laptop} {
    font-size: 6rem;
  }
`;

export const FontTwo = styled.p`
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.textWhite};
  font-size: 4rem;
  padding-bottom: 6rem;

  ${breakpoints.s} {
    font-size: 3.5rem;
  }

  ${breakpoints.laptop} {
    font-size: 6rem;
  }
`;
