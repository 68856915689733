import {useEffect} from "react";
import { useLocation } from "react-router-dom";

export const useScrollTo = () => {
 // creates location object 
 const location = useLocation()

 useEffect(()=> {
   if (location.hash) {
       let element = document.getElementById(location.hash.slice(1))
       if (element) {
           element.scrollIntoView()
       }
   } else {
   window.scrollTo({top:0,left:0})
   }
 }, [location]) 
  
  
    return (
    {location}
  )
}
