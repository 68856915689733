import {useRef} from 'react'

const useScrollToSection = () => {
  
    const aboutRef = useRef();

  const scrollToSection = (elementRef) => {
      window.scrollTo({
          top:elementRef.current
        
      })
      console.log(elementRef.current)
  }
  
    return (
        
    {aboutRef, scrollToSection}
  )
}

export default useScrollToSection