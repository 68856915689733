import React from "react";
import { projectOneFont, projectTwoFont } from "./data";
import { Heading, PageContainer, FontOne, FontTwo, FontSectionContainer } from "./FontSection.style";
import { motion } from "framer-motion";
const FontSection = ({ mode }) => {
  let yerbaheadingFontName = projectOneFont.headingFontName;
  let peppheadingFontName = projectTwoFont.headingFontName;

  return (
    <FontSectionContainer mode={mode}>
    <PageContainer mode={mode}>
      <Heading
        as={motion.h1}
        initial={{ opacity: 0, y: 15 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        viewport={{ margin: "-150px", once: true  }}
      >
        fonts<span>.</span>
      </Heading>
      {mode === "pepp" ? (
        <FontOne
          as={motion.p}
          initial={{ opacity: 0, y: 15 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-150px", once: true  }}
        >
          {peppheadingFontName}
        </FontOne>
      ) : (
        <FontOne
          as={motion.p}
          initial={{ opacity: 0, y: 15 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-150px", once: true  }}
          style={{ fontFamily: "'Londrina Solid', cursive" }}
        >
          {yerbaheadingFontName}
        </FontOne>
      )}
      <FontTwo
        as={motion.p}
        initial={{ opacity: 0, y: 15 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.2 }}
        viewport={{ once: true }}
      >
        Nunito
      </FontTwo>
    </PageContainer>
    </FontSectionContainer>
  );
};

export default FontSection;
