import styled from 'styled-components/macro'
import { Container } from "../../globalStyle";
import { breakpoints } from '../../Themes';

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items:center;
  background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgBlue : props.theme.bgWhite)};
  ${Container}
`;

export const ObjectiveSectionContainer = styled.div` 
display: flex;
justify-content:center;
align-items:center;
flex-direction:column;
height:100vh;
background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgBlue : props.theme.bgWhite)};
color: ${( props ) => (props.mode === 'pepp' ? props.theme.peppTextWhite : props.theme.textBlack)};

`

export const ObjectiveHeader = styled.h1` 
font-weight: 900;
letter-spacing: -0.03em;
transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
text-align:center;

span{
    color: ${( props ) => (props.mode === 'pepp' ? props.theme.peppAccentOrange : props.theme.yerbaAccentGreen)};
  }

  ${breakpoints.s} {
    font-size:3.4rem;
    padding-top:3rem;
    
  }

  ${breakpoints.m} {
    font-size:4rem;
  }

  ${breakpoints.l} {
    font-size:4rem;
  }

  ${breakpoints.tablet} {
    font-size:4.5rem;
    margin-top:4rem;
  }

  ${breakpoints.laptop} {
    font-size:5rem;
    padding-top:0rem;
  }

  ${breakpoints.desktop} {
    font-size:6rem;
  }

`

export const BodyCopy = styled.div` 
font-size:1.2rem;
padding-top:2rem;
width:100%;
margin:0 auto;
font-weight:500;
padding-bottom:6rem;

line-height:160%;


${breakpoints.s}{

}

${breakpoints.m}{

}

${breakpoints.l} {
    
  }

  ${breakpoints.tablet} {
    width:100%;
    font-size:1.5rem;
  }

  ${breakpoints.laptop} {
    width:58%;
  }
`

