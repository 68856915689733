import { motion } from "framer-motion";
import { AiFillGithub } from "react-icons/ai";
import { techObj } from "./Data";
import {
  SiJavascript,
  SiNextdotjs,
  SiFramer,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiGraphql,
  SiTailwindcss,
  SiStyledcomponents
} from "react-icons/si";
import { FaReact, FaGitAlt } from "react-icons/fa";
import { IoLogoFigma } from "react-icons/io5";
import {
  TechSectionContainer,
  PageContainer,
  PageHeading,
  IconContainer,
} from "./TechSection.styles";
const TechSection = () => {
  return (
    <TechSectionContainer id="technologies">
      <PageContainer>
        <PageHeading
          as={motion.p}
          initial={{ opacity: 0, y: 15 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-150px", once: true  }}
        >
          {techObj.heading}
          <span>{techObj.dot}</span>
        </PageHeading>
        <IconContainer
          as={motion.div}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-150px", once: true  }}
        >
          <SiJavascript />
          <FaReact />
          <SiNextdotjs />
          <SiGraphql />
          <SiTailwindcss />
          <SiStyledcomponents />
          <FaGitAlt />
          <AiFillGithub />
          <IoLogoFigma />
          <SiFramer />
          <SiAdobephotoshop />
          <SiAdobeillustrator />
        </IconContainer>
      </PageContainer>
    </TechSectionContainer>
  );
};

export default TechSection;
