import styled from 'styled-components/macro'
import { Container } from "../../globalStyle";
import { breakpoints } from '../../Themes';

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items:center;
  background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgBlue : props.theme.bgWhite)};
  ${Container}
  
`;

export const UXSectionContainer = styled.div` 
display: flex;
justify-content:center;
align-items:center;
flex-direction:column;

padding-bottom:3rem;
background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgBlue : props.theme.bgWhite)};
color: ${( props ) => (props.mode === 'pepp' ? props.theme.peppTextWhite : props.theme.textBlack)};

height:100vh;
`

export const UXHeader = styled.h1`
font-size:3rem;
font-weight: 900;
text-align:center;
letter-spacing: -0.03em;
transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

span{
  color: ${( props ) => (props.mode === 'pepp' ? props.theme.peppAccentOrange : props.theme.yerbaAccentGreen)};
  }

  ${breakpoints.s} {
    font-size:3.5rem;
    padding-top:3rem;
  }

  ${breakpoints.m} {
    font-size:4rem;
  }

  ${breakpoints.l} {
    font-size:4.5rem;
  }

  ${breakpoints.tablet} {
    font-size:5rem;
  }

  ${breakpoints.laptop} {
    font-size:5rem;
  }

  ${breakpoints.desktop} {
    font-size:6rem;
  }

`

export const BodyCopy = styled.p` 
font-size:1.2rem;
padding-top:1rem;
font-weight:500;
margin:0 auto;
line-height:160%;

${breakpoints.s}{
    width:100%;
}

${breakpoints.m}{
    
}

${breakpoints.l} {
    width:100%;
  }

  ${breakpoints.tablet} {
    width:100%;
    font-size:1.5rem;
  }

  ${breakpoints.laptop} {
    width:55%;
    margin:0 auto;
    
  }

`




