import { aboutObj } from "./Data";
import { motion } from "framer-motion";
import {
  AboutSectionContainer,
  PageContainer,
  TopLine,
  BodyCopy,
  PassionSpan,
  CommunicationSpan,
  TextContainer,
  FirstLine,
  SecondLine,
  ThirdLine,
} from "./AboutSection.styles";

const AboutSection = () => {
  return (
    <div>
      <AboutSectionContainer id="about">
        <PageContainer>
          <TextContainer>
            <TopLine
              as={motion.p}
              initial={{ opacity: 0, y: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-130px", once: true }}
            >
              {aboutObj.topline}
            </TopLine>

            <FirstLine
              as={motion.p}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true }}
            >
              {aboutObj.firstLineHeading}
            </FirstLine>
            <SecondLine
              as={motion.p}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true }}
            >
              {aboutObj.secondLineHeading[0]}
              <PassionSpan>{aboutObj.span[0]}</PassionSpan>
              {aboutObj.secondLineHeading[1]}
            </SecondLine>
            <ThirdLine
              as={motion.p}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true }}
            >
              <CommunicationSpan>
                {aboutObj.thirdLineHeading}
                <span>{aboutObj.span[1]}</span>
              </CommunicationSpan>
            </ThirdLine>
            <BodyCopy
              as={motion.p}
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true }}
            >
              {aboutObj.bodyCopy}
            </BodyCopy>
          </TextContainer>
        </PageContainer>
      </AboutSectionContainer>
    </div>
  );
};

export default AboutSection;
