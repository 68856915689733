import React from "react";
import { motion } from "framer-motion";
import { yerbaText, peppText } from "./data";

import {
  TechSectionContainer,
  PageContainer,
  TechHeader,
  TextContainer,
} from "./ProjectTechSection.styles";

const ProjectTechSection = (props) => {
  return (
    <TechSectionContainer>
      <PageContainer>
        <TechHeader
          as={motion.h1}
          initial={{ opacity: 0, y: 15 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-150px", once: true  }}
          mode={props.mode}
        >
          tech<span>.</span>
        </TechHeader>
        <TextContainer>
          <div>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              <strong>
                {props.mode === "pepp"
                  ? peppText.subHeading
                  : yerbaText.subHeading}
              </strong>
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              {props.mode === "pepp" ? peppText.body : yerbaText.body}
            </motion.p>
          </div>

          <div>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              <strong>
                {props.mode === "pepp"
                  ? peppText.subHeadingTwo
                  : yerbaText.subHeadingTwo}
              </strong>
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              {props.mode === "pepp" ? peppText.bodyTwo : yerbaText.bodyTwo}
            </motion.p>
          </div>

          <div>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              <strong>
                {props.mode === "pepp"
                  ? peppText.subHeadingThree
                  : yerbaText.subHeadingThree}
              </strong>
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: 15 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              viewport={{ margin: "-150px", once: true  }}
            >
              {props.mode === "pepp" ? peppText.bodyThree : yerbaText.bodyThree}
            </motion.p>
          </div>
        </TextContainer>
      </PageContainer>
    </TechSectionContainer>
  );
};

export default ProjectTechSection;
