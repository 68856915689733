import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const TechSectionContainer = styled.div`
  background: ${(props) =>
    props.mode === "pepp" ? props.theme.peppBgBlue : props.theme.bgWhite};
  color: ${(props) =>
    props.mode === "pepp" ? props.theme.peppTextWhite : props.theme.textBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const PageContainer = styled(Container)`
  color: ${(props) =>
    props.mode === "pepp" ? props.theme.peppTextWhite : props.theme.textBlack};
  background: ${(props) =>
    props.mode === "pepp" ? props.theme.peppBgBlue : props.theme.bgWhite};
  padding-bottom: 2rem;

  ${breakpoints.s} {
    padding-bottom: 2rem;
  }

  ${breakpoints.l} {
    padding-bottom: 2rem;
    padding-top: 0rem;
  }

  ${breakpoints.tablet} {
    padding-bottom: 8rem;
    padding-top: 0;
  }

  ${breakpoints.laptop} {
    padding-bottom: 4;
    padding-top: 0;
  }

  ${breakpoints.desktop} {
    padding-bottom: 4rem;
    padding-top: 0;
    min-height:90vh;
  }

  @media screen and (max-width: 768px) {
    padding-right: 3em;
    padding-left: 3em;
  }

  @media screen and (min-width: 280px) and (max-width: 767px) {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  ${Container}
`;

export const TechHeader = styled.div`
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  letter-spacing: -0.03em;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  span {
    color: ${(props) =>
      props.mode === "pepp"
        ? props.theme.peppAccentOrange
        : props.theme.yerbaAccentGreen};
  }

  ${breakpoints.s} {
    font-size: 3.5rem;
    margin-top: 2rem;
  }

  ${breakpoints.m} {
    font-size: 4rem;
  }

  ${breakpoints.l} {
    font-size: 4.5rem;
    margin-top: 2rem;
  }

  ${breakpoints.tablet} {
    font-size: 5rem;
    margin-top: 4rem;
  }

  ${breakpoints.laptop} {
    font-size: 5rem;
    margin-top: 8rem;
  }

  ${breakpoints.desktop} {
    font-size: 6rem;
  }
`;

export const Bodycopy = styled.div`
  ${breakpoints.s} {
    padding-top: 1rem;
  }

  ${breakpoints.tablet} {
    width: 60%;
  }
`;

export const TextContainer = styled.div`
  margin-top: 2rem;
  font-size: 1.2rem;

  p {
    line-height: 160%;
  }

  ${breakpoints.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  p:nth-of-type(1) {
    padding-bottom: 0.5rem;
  }

  div {
    ${breakpoints.s} {
      padding-bottom: 1rem;
    }

    ${breakpoints.tablet} {
      padding-left: 8rem;
      padding-right: 8rem;
      padding-bottom: 1rem;
    }

    ${breakpoints.laptop} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;
