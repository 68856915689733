import { motion } from "framer-motion";
import {
  ContactSectionContainer,
  PageContainer,
  ContactContainer,
} from "./ContactSection.styles";
import { contactObj } from "./Data";

const ContactSection = () => {
  return (
    <ContactSectionContainer id="contact">
      <PageContainer >
        <ContactContainer
          // as={motion.div}
          // initial={{ opacity: 0 }}
          // whileInView={{ opacity: 1 }}
          // transition={{ duration: 0.3 }}
          // viewport={{ margin: "-150px" }}
        >
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            viewport={{ margin: "-150px", once: true  }}
          
          
          >{contactObj.email}</motion.p>
          <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5}}
          viewport={{ once: true  }}
          
          
          >copyright &copy; Yofal {new Date().getFullYear()}</motion.p>
        </ContactContainer>
      </PageContainer>
    </ContactSectionContainer>
  );
};

export default ContactSection;
