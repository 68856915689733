import styled from 'styled-components'
import { Container } from "../../globalStyle";
import { breakpoints } from '../../Themes';

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgWhite : props.theme.yerbaBgGreen)};

  @media screen and (max-width: 768px) {
    padding-right: 3em;
    padding-left: 3em;
  }

  @media screen and (min-width: 280px) and (max-width: 767px) {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  ${Container}
`;


export const ProjectHeaderSectionContainer = styled.div`
background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgWhite : props.theme.yerbaBgGreen)};
display:flex;
align-items:center;
min-height:90vh;
margin:0 auto;

`
export const Header = styled.header`
text-align:center;
padding:1rem;


img{
    width:100%;
    padding:1rem;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ${breakpoints.tablet} {
      width:60%;
  }

  ${breakpoints.laptop} {
  width:60%;
  }

    ${breakpoints.desktop} {
      width:40%;
      
  }
}
`

