import React from "react";
import { motion } from "framer-motion";

import { ColorHeader, PageContainer, ColorPaletteContainer, ColorSectionContainer } from "./ColorSection.styles";

const ColorSection = (props) => {
  return (
    <ColorSectionContainer>
    <PageContainer mode={props.mode}>
      <ColorHeader as={motion.h1}
      initial={{ opacity: 0, y: 15 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      viewport={{ margin: "-160px", once: true  }}
      >
        colors<span>.</span>
      </ColorHeader>
      <ColorPaletteContainer
          as={motion.div}
          initial={{ opacity: 0, y: 15 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-160px", once: true  }}
      

       theme={props.theme} mode={props.mode}>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      <div mode={props.mode}></div>
      </ColorPaletteContainer>
    </PageContainer>
     </ColorSectionContainer>
  );
};

export default ColorSection;
