import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content:center;
  ${Container}
  
`;

export const HeaderSectionContainer = styled.section`
  background: ${({ theme }) => theme.primary};

  width: 100%;

  ${breakpoints.s} {
    /* padding-bottom:12rem; */
    display:flex;
    justify-content:center;
    height: calc(100vh - 4rem);
  }
  ${breakpoints.m} {
  
  }
  ${breakpoints.l} {
    /* padding-bottom:20rem; */
  }

  ${breakpoints.tablet} {
    /* padding-bottom:8rem; */
    height: calc(100vh - 96px);
    
  }

  ${breakpoints.laptop} {
    /* padding-bottom:2rem; */
  }

  ${breakpoints.desktop} {
 
  }

`;

export const MainHeading = styled.h1`
  font-weight: 900;
  max-width: 67rem;
  line-height: 85%;
  white-space:nowrap;
  color: ${({ theme }) => theme.textWhite};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  letter-spacing: -0.07em;

  span {
    color: ${({ theme }) => theme.accent};
  }

  ${breakpoints.s} {
    /* padding-top: 8rem; */
    font-size: 1.8rem;
    padding:0;
  }
  ${breakpoints.m} {
    font-size: 2rem;
  }
  ${breakpoints.l} {
    /* padding-top: 10rem; */
    font-size: 1.9rem;
  }

  ${breakpoints.tablet} {
    /* padding-top: 19.5rem; */
    font-size: 3.8rem;
  }

  ${breakpoints.laptop} {
    font-size: 5.5rem;
    max-width: 15em;
    /* padding-top: 14rem; */
  }

  ${breakpoints.desktop} {
    font-size: 6rem;
    padding:0;
    
  }
`;

export const SubHeading = styled.p`
  font-style: normal;
  font-weight: 400;
  margin-top: 1em;
  text-transform: uppercase;
  color: white;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    font-size: 0.9rem;
    letter-spacing: 0em;
  }
  ${breakpoints.m} {
    font-size: 1rem;
    letter-spacing: 0em;
  }
  ${breakpoints.l} {
    font-size: 0.9rem;
    letter-spacing: 0em;
  }

  ${breakpoints.tablet} {
    font-size: 1.3rem;
    letter-spacing: 0.1em;
  }
  ${breakpoints.laptop} {
    font-size: 1.2rem;
  }
  ${breakpoints.desktop} {
    font-size: 1.5rem;
    letter-spacing: 0.2em;
  }
`;

export const ContactItem = styled.div`
   ${breakpoints.s} {
      display: none;
  }

  ${breakpoints.laptop} {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`;

export const ContactLink = styled.p`
  color: #ede9e8;
  text-decoration: none;
  transform: rotate(90deg);
  position: absolute;
  right: -2em;
  top: 50%;
  font-size: 0.9em;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${breakpoints.s} {
      display: none;
  }
  ${breakpoints.laptop} {
    top: 60%;
    display: inline;
    position: fixed;
  }

`;

export const Icons = styled.div`
   ${breakpoints.s} {
      display: none;
  }

  ${breakpoints.laptop} {
  position: fixed;
  top: 8rem;
  right: 0.5em;
  display: grid;
  grid-template-rows: 3rem 3rem;
  justify-content: end;
  font-size: 2rem;
  color: ${({ theme }) => theme.textWhite};
  }

  a {
    color: ${({ theme }) => theme.textWhite};
    &:hover {
      color: ${({ theme }) => theme.primaryDarker};
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }
`;
