export const yerbaText = {
    subHeading:'Front-end',
    subHeadingTwo:'Back-end',
    subHeadingThree:'Styling',
    body:'The project uses the React framework Next.js, which among other things offers SSR and an easy way to dynamically render pages.',
    bodyTwo:'Using Sanity.io as the headless CMS-system simplifies collaboration and the creation of a customizable systems for end-users.',
    bodyThree:'Tailwind CSS works well with Next.js for a more consistent developer experience without conflicting classnames.',

}

export const peppText = {
    subHeading:'Front-end',
    subHeadingTwo:'Back-end',
    subHeadingThree:'Styling',
    body:'The front-end is built with the well-known JavaScript framework React. With a large community and a great number of NPM-packages to help with the development, React is the natural choice.',
    bodyTwo:'Using Sanity.io as the headless CMS-system simplifies collaboration and the creation of a customizable systems for end-users. ',
    bodyThree:'Styled Components was used for writing all the CSS. Styled Components makes writing CSS-in-JS easy and offers great customizability. ',
}