import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  ProjectHeaderSection,
  ObjectiveSection,
  BackgroundSection,
  UXSection,
  UISection,
  ColorSection,
  FontSection,
  ProjectTechSection,
} from "../../components";

const SecondProject = () => {
  return (
    
      <HelmetProvider>
      <Helmet>
        <title>Yofal | Pepp & Svett </title>
        <meta name="description" content="yofal portfolio" />
      </Helmet>
      <ProjectHeaderSection mode="pepp" />
      <ObjectiveSection mode="pepp" />
      <BackgroundSection mode="pepp" />
      <UXSection mode="pepp" />
      <UISection mode="pepp" />
      <ColorSection mode="pepp" />
      <FontSection mode="pepp" />
      <ProjectTechSection mode="pepp" />
      </HelmetProvider>
    
  );
};

export default SecondProject;
