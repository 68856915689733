import React, { useState, useEffect, useRef } from "react";

const useNavToggle = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const hideMenu = () => {
    setClick(false);
  };

  // if screen is resized mobile menu will close
  const [width, setWidth] = useState(0);
  
  // initalizing widthRef
  const widthRef = useRef(width);

  React.useEffect(() => {
    widthRef.current = width;
  });

  useEffect(() => {
    // function should be inside of useEffect 
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (widthRef.current > 0) {
        setClick(false);
      }
    };
// eventListeners 

    window.addEventListener("resize", handleResize);
    
    // cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);

  return { click, handleClick, hideMenu };
};

export default useNavToggle;
