import styled from "styled-components/macro";
import { breakpoints } from "../../Themes";

export const PageContainer = styled.div` 
max-width: 1440px;
margin:0 auto;
`
export const AboutSectionContainer = styled.section`
  background: ${({ theme }) => theme.primary};
  padding-bottom: 4rem;
  width: 100%;
  height:100vh;
  display: flex;
  flex-direction: column;
  justify-content:center;

  ${breakpoints.s} {
    padding-top: 8rem;
    padding-left:1rem;
    padding-right:1rem;
  }

  ${breakpoints.m} {

  }

  ${breakpoints.l} {
    padding-top: 6rem;
  }
  ${breakpoints.tablet} {
    padding-top: 15rem;
    padding-left:3rem;
    padding-right:3rem;
  }

  ${breakpoints.laptop} {
    font-size: 1.2rem;
    margin-bottom: -0.8rem;
    padding-left:4.5rem;
    padding-right:4.5rem;
    padding-top:6rem;
    
  }
  ${breakpoints.desktop} {
    padding-top:8rem;
  }
`;


export const TextContainer = styled.div`
  color: ${({ theme }) => theme.textWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height:150%;
  margin: 0 auto;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${breakpoints.tablet} {
    line-height:200%;
  }
`;

export const TopLine = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    font-size: 0.8rem;
  }

  ${breakpoints.m} {
    font-size: 0.8rem;
    padding-top:1rem;
  }

  ${breakpoints.l} {
    font-size: 1rem;
    padding-top:4rem;
  }
  ${breakpoints.tablet} {
    font-size: 1.1rem;
  }

  ${breakpoints.laptop} {
    font-size: 1.2rem;
    padding-top:12rem;
    
  }
  ${breakpoints.desktop} {
    padding-top:0rem;
  }
`;
export const FirstLine = styled.p`
  letter-spacing: -0.08em;
  font-style: normal;
  font-weight: 900;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    font-size: 1.6rem;
  }

  ${breakpoints.m} {
    font-size: 2rem;
    margin-bottom:0.3rem;
  }

  ${breakpoints.l} {
    font-size: 2.1rem;
    margin-bottom:0.4rem;
  }
  ${breakpoints.tablet} {
    font-size: 3.5rem;
    margin-bottom:1rem;
  }

  ${breakpoints.laptop} {
    font-size: 4.5rem;
    margin-bottom:1.5rem;
  }
  ${breakpoints.desktop} {
    font-size: 5rem;
    margin-bottom:2rem;
  }
`;
export const SecondLine = styled.p`
  font-style: normal;
  font-weight: 900;
  letter-spacing: -0.08em;
  
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    font-size: 1.6rem;
    margin-bottom:0.2rem;
  }

  ${breakpoints.m} {
    font-size: 1.9rem;
    margin-bottom:0.5rem;
  }

  ${breakpoints.l} {
    font-size: 2.1rem;
    margin-bottom:0.6rem;
  }
  ${breakpoints.tablet} {
    font-size: 3.5rem;
    letter-spacing: -0.04em;
    margin-bottom:1.5rem;
    
  }

  ${breakpoints.laptop} {
    font-size: 4.5rem;
    margin-bottom:1.8rem;
    letter-spacing: -0.07em;

    
  }
  ${breakpoints.desktop} {
    font-size: 5rem;
    margin-bottom:2.5rem;
    letter-spacing: -0.03em;
   
  }
`;
export const ThirdLine = styled.p`
  font-style: normal;
  font-weight: 900;
  

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${breakpoints.s} {
  }

  ${breakpoints.m} {
    font-size: 2rem;
  }

  ${breakpoints.l} {
    font-size: 2rem;
  }
  ${breakpoints.tablet} {
    font-size: 3rem;
  }

  ${breakpoints.laptop} {
    font-size: 4rem;
    
  }

  ${breakpoints.desktop} {
    font-size: 5rem;
  }
`;

export const BodyCopy = styled.p`
  margin-top: 1em;
  font-size: 1.2rem;
  line-height: 160%;
  color: ${({ theme }) => theme.textWhite};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    font-size: 1rem;
  }

  ${breakpoints.m} {
    font-size: 1.1rem;
  }

  ${breakpoints.l} {
    font-size: 1.2rem;
  }
  ${breakpoints.tablet} {
    font-size: 1.3rem;
    width: 65%;
  }

  ${breakpoints.laptop} {
    font-size: 1.3rem;
    width: 54%;
    margin-top: 1.5em;
  }
`;
export const PassionSpan = styled.span`
  color: ${({ theme }) => theme.accent};
  padding-left: 0.2em;
  padding-right: 0.2em;

  ${breakpoints.s} {
    font-size: 1.6rem;
    letter-spacing: -0.09em;
  }

  ${breakpoints.m} {
    font-size: 1.9rem;
  }

  ${breakpoints.l} {
    font-size: 2.1rem;
  }
  ${breakpoints.tablet} {
    font-size: 3.5rem;
  }

  ${breakpoints.laptop} {
    font-size: 4.6rem;
  }

  ${breakpoints.desktop} {
    font-size: 5rem;
    padding-right:1rem;
    margin-left:0.1rem;
    
  }
`;
export const CommunicationSpan = styled.span`
  text-transform: uppercase;
  padding-top: 20px;

  letter-spacing: -0.09em;

  span {
    padding-left: 0.02em;
    color: white;
  }

  ${breakpoints.s} {
    font-size: 2rem;
  }

  ${breakpoints.m} {
    font-size: 2.3rem;
  }

  ${breakpoints.l} {
    font-size: 2.6rem;
  }
  ${breakpoints.tablet} {
    font-size: 4.5rem;
  }

  ${breakpoints.laptop} {
    font-size: 5.5rem;
  }

  ${breakpoints.desktop} {
    font-size: 6.6rem;
  }
`;
