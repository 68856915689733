import React from "react";

import { motion } from "framer-motion";
import { yerbaText, peppText } from "./data";

import {
  ObjectiveHeader,
  ObjectiveSectionContainer,
  PageContainer,
  BodyCopy,
} from "./ObjectiveSection.styles";

const ObjectiveSection = (props) => {
  return (
      <ObjectiveSectionContainer mode={props.mode}>
    <PageContainer mode={props.mode}>
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          whileInView={{ opacity: 1, y: 0, 
            transition:{
            type: "spring",
            duration: 0.5,
          } }}
          viewport={{ margin: "-160px", once: true  }}
        >
          <ObjectiveHeader mode={props.mode}>
            objective<span>.</span>
          </ObjectiveHeader>
          <BodyCopy>
            {props.mode === "pepp" ? peppText.text : yerbaText.text}
          </BodyCopy>
        </motion.div>
    </PageContainer>
      </ObjectiveSectionContainer>
  );
};

export default ObjectiveSection;
