import React from "react";
import { motion } from "framer-motion";
import { yerbaText, peppText } from "./data";
import {
  UXHeader,
  UXSectionContainer,
  PageContainer,
  BodyCopy,
} from "./UXSection.styles";

const UXSection = (props) => {
  console.log();
  return (
    <UXSectionContainer>
      <PageContainer>
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ margin: "-160px", once: true  }}
        >
          <UXHeader mode={props.mode}>
            UX<span>.</span>
          </UXHeader>
          <BodyCopy>
            {props.mode === "pepp" ? peppText.text : yerbaText.text}
          </BodyCopy>
        </motion.div>
      </PageContainer>
    </UXSectionContainer>
  );
};

export default UXSection;
