import useNavToggle from "../../hooks/useNavToggle";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { FiSun, FiMoon } from "react-icons/fi";
import useScrollToSection  from "../../hooks/useScrollToSection";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  LogoWrapper,
  TogglerWrapper,
} from "./Navbar.styles";
import logo from "../../assets/img/logo.svg";

const Navbar = ({ themeToggler, theme }) => {

  const {click, handleClick, hideMenu} = useNavToggle();
const {scrollToSection} = useScrollToSection();

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/" onClick={hideMenu}>
              <LogoWrapper>
                <img src={logo} alt="" />
              </LogoWrapper>
            </NavLogo>

            <MobileIcon onClick={handleClick} to="home">
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to="/#projects" onClick={hideMenu}>
                  projects
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#about" onClick={scrollToSection}>
                  about
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#technologies" onClick={hideMenu}>
                  tech
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/#contact" onClick={hideMenu}>
                  contact
                </NavLinks>
              </NavItem>
              <NavItem>
                <TogglerWrapper onClick={themeToggler}>
                  {theme === "light" ? <FiMoon /> : <FiSun />}
                </TogglerWrapper>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
