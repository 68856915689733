import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  ProjectHeaderSection,
  ObjectiveSection,
  BackgroundSection,
  UXSection,
  UISection,
  ColorSection,
  FontSection,
  ProjectTechSection,
} from "../../components";

const FirstProject = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Yofal | El Mate Dorado </title>
        <meta name="description" content="yofal portfolio" />
      </Helmet>
      <ProjectHeaderSection />
      <ObjectiveSection />
      <BackgroundSection />
      <UXSection />
      <UISection />
      <ColorSection />
      <FontSection />
      <ProjectTechSection />
      </HelmetProvider>
  );
};

export default FirstProject;
