import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content:center;
  ${breakpoints.s} {
    padding:0;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.l} {
  }

  ${breakpoints.tablet} {
  padding-right: 4.5em;
  padding-left: 4.5em;
    
  }
  ${Container}

  
`;

export const ProjectSectionContainer = styled.section`
background: ${({ theme }) => theme.primary};

  ${breakpoints.s} {
  }
  ${breakpoints.m} {
  }
  ${breakpoints.l} {
  }

  ${breakpoints.tablet} {

    padding-top: 2rem;
  }

  ${breakpoints.laptop} {

    padding-top: 6rem;
  }

  ${breakpoints.desktop} {
  }
`;

export const ProjectLink = styled.a`
  text-decoration: none;
`;

export const PageHeading = styled.h2`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  letter-spacing: -0.06em;
  padding-top: 5rem;
  padding-bottom: 2rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  span {
    color: ${({ theme }) => theme.accent};
  }
  ${breakpoints.s} {
    text-align: center;
    font-size: 3rem;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.l} {
  }

  ${breakpoints.tablet} {
    
    font-size: 3rem;
    padding-top: 8rem;
  }

  ${breakpoints.laptop} {
    text-align: start;
    font-size: 3rem;
    padding-top: 7rem;
  }

  ${breakpoints.desktop} {
    font-size: 5rem;
  }
`;

export const ProjectImg = styled.img`
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    width: 100%;
    display: none;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.l} {
  }

  ${breakpoints.laptop} {
    display: block;
    width: 400px;
    margin-left: 1.5em;
    margin-right: 2.5em;

    margin-top: 3.5rem;
    padding-bottom: 2em;
    object-fit: contain;
  }

  ${breakpoints.desktop} {
    display: block;
    width: 440px;
    margin-left: 1em;
    margin-right: 2.5em;
    margin-top: 0rem;
    padding-bottom: 0em;
  }
`;

export const SecondProjectImg = styled.img`
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${breakpoints.s} {
    width: 100%;
    display: none;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.l} {
  }

  ${breakpoints.laptop} {
    display: block;
    width: 400px;
    margin-right: 1.5em;
    margin-left: 2.5em;

    margin-top: 3.5rem;
    padding-bottom: 2em;
    object-fit: contain;
  }

  ${breakpoints.desktop} {
    display: block;
    width: 440px;
    margin-left: 1em;
    margin-right: 2.5em;
    margin-top: 0rem;
    padding-bottom: 0em;
  }
`;

export const MobileProjectImg = styled.img`
  ${breakpoints.s} {
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 2em;
    width: 50%;
    margin-top: 2em;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    display: none;
  }
`;

export const ProjectItemContainer = styled.div`

  ${breakpoints.s} {
    width: 100%;
    padding: 0;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const SecondProjectItemContainer = styled.div`
  ${breakpoints.s} {
    width: 100%;
    padding: 0;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const ProjectItem = styled.div`
  background: ${({ theme }) => theme.primaryLighter};
  &:hover {
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  ${breakpoints.s} {
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 2em;
    width: 100%;

    display: flex;

   
    margin-bottom: 4em;
    padding-bottom: 1em;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
    border-radius: 8px;
  }
  ${breakpoints.laptop} {
    margin: 0;
    display: flex;
    flex-direction: row;
    grid-column: 1/5;
    border-radius: 8px;
    margin-bottom: 4em;
    padding: 0em;
    padding-bottom: 1em;
    width: 795px;
  }
  ${breakpoints.desktop} {
    width: 895px;
  }
`;

export const SecondProjectItem = styled.div`
  background: ${({ theme }) => theme.primaryLighter};
  &:hover {
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }

  ${breakpoints.s} {
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 2em;
    width: 100%;
    grid-column: 1/6;
    display: flex;
   
    margin-bottom: 4em;
    padding: 1em;
    padding-bottom: 1em;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
    border-radius: 8px;
  }
  ${breakpoints.laptop} {
    margin: 0;
    display: flex;
    flex-direction: row;
    grid-column: 5/5;
    border-radius: 8px;
    margin-bottom: 4em;
    padding: 0em;
    padding-bottom: 1em;
    width: 795px;

    flex-direction: row-reverse;
  }
  ${breakpoints.desktop} {
    width: 895px;
  }
`;

export const TextContainer = styled.div`
  ${breakpoints.s} {
    text-align: center;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    min-width: 30em;
    padding-top: 3rem;
    position: relative;
    padding-left: 2rem;
    text-align: initial;
  }
  ${breakpoints.desktop} {
    min-width: 45em;
    padding-top: 1rem;
  }
`;

export const SecondTextContainer = styled.div`
  ${breakpoints.s} {
    text-align: center;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    min-width: 30em;
    padding-top: 6rem;
    position: relative;
    padding-right: 1rem;
    text-align: right;
  }
  ${breakpoints.desktop} {
    min-width: 45em;
    padding-top: 4rem;
  }
`;

export const TopLine = styled.p`
  color: ${({ theme }) => theme.textWhite};

  text-transform: uppercase;
  font-weight: 400;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    margin-top: 3em;
    font-size: 0.6rem;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
    font-size: 0.8rem;
  }
  ${breakpoints.laptop} {
    font-size: 1rem;
  }
`;
export const SecondTopLine = styled.p`
  color: ${({ theme }) => theme.textWhite};

  text-transform: uppercase;
  font-weight: 400;

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  ${breakpoints.s} {
    font-size: 0.6rem;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
    font-size: 0.8rem;
  }
  ${breakpoints.laptop} {
    font-size: 1rem;
  }
`;

export const ProjectHeading = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  letter-spacing: -0.04em;

  ${breakpoints.s} {
    font-size: 2em;
  }
  ${breakpoints.m} {
    font-size: 2em;
    padding-bottom: 0.2em;
    min-width: 1em;
  }
  ${breakpoints.tablet} {
    font-size: 2.5em;
    padding-bottom: 0.2em;
  }
  ${breakpoints.laptop} {
    font-size: 3.5rem;
  }
  ${breakpoints.desktop} {
    font-size: 5rem;
  }
`;
export const SecondProjectHeading = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  letter-spacing: -0.04em;

  ${breakpoints.s} {
    font-size: 2em;
  }
  ${breakpoints.m} {
    font-size: 2em;
    padding-bottom: 0.2em;
    min-width: 1em;
  }
  ${breakpoints.tablet} {
    font-size: 2.5em;
    padding-bottom: 0.2em;
  }
  ${breakpoints.laptop} {
    font-size: 3.5rem;
  }
  ${breakpoints.desktop} {
    font-size: 5rem;
  }
`;
export const ProjectSubHeading = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  font-style: italic;
  letter-spacing: -0.04em;
  margin-top: -0.2em;

  ${breakpoints.s} {
    font-size: 1.3em;
    padding-bottom: 2em;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
  }
  ${breakpoints.desktop} {
    font-size: 2rem;
    margin-top: -0.8em;
  }
`;

export const SecondProjectSubHeading = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-weight: 900;
  font-style: italic;
  letter-spacing: -0.04em;
  margin-top: -0.2em;

  ${breakpoints.s} {
    font-size: 1.3em;
    padding-bottom: 2em;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
  }
  ${breakpoints.desktop} {
    font-size: 2rem;
    margin-top: -0.8em;
  }
`;

export const ProjectBtn = styled.p`
  color: ${({ theme }) => theme.textWhite};
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5rem;
  &:hover {
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.primaryDarker};
  }
  &:active {
    color: ${({ theme }) => theme.primaryDarker};
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 280px) {
    font-size: 0.6rem;
  }

  ${breakpoints.s} {
    padding: 0;
    text-align: center;
    font-size: 0.8rem;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    text-align: initial;
    padding-left: 2.5rem;
  }
  ${breakpoints.desktop} {
  }
`;

export const SecondProjectBtn = styled.p`
  color: ${({ theme }) => theme.textWhite};
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.5rem;
  padding-left: 17em;
  &:hover {
    color: ${({ theme }) => theme.primaryDarker};
    transition: all 0.3s ease;
  }
  &:active {
    color: ${({ theme }) => theme.primaryDarker};
    transition: all 0.3s ease;
  }

  ${breakpoints.s} {
    padding: 0;
    text-align: center;
    font-size: 0.8rem;
  }
  ${breakpoints.m} {
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    text-align: right;
    padding-right: 2.5rem;
  }
  ${breakpoints.desktop} {
  }
`;

export const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5em 1.5em 1.5em 1.5em;
  justify-content: center;
  grid-gap: 1em;
  width: 42%;
  color: ${({ theme }) => theme.textWhite};

  ${breakpoints.s} {
    font-size: 1.5em;
    grid-gap: 1em;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  ${breakpoints.m} {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    grid-gap: 1em;
    margin-bottom: 2em;
    font-size: 1.8em;
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    text-align: initial;
    justify-content: start;
  }

  ${breakpoints.desktop} {
    font-size: 2rem;
  }
`;
export const SecondIconContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5em 1.5em 1.5em 1.5em;
  justify-content: center;
  grid-gap: 1em;
  width: 42%;
  color: ${({ theme }) => theme.textWhite};

  ${breakpoints.s} {
    font-size: 1.5em;
    grid-gap: 1em;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  ${breakpoints.m} {
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    grid-gap: 1em;
    margin-bottom: 2em;
    font-size: 1.8em;
  }
  ${breakpoints.tablet} {
  }
  ${breakpoints.laptop} {
    text-align: right;
    justify-content: end;
  }

  ${breakpoints.desktop} {
    font-size: 2rem;
  }
`;
