export const aboutObj = {
    topline: 'about me',
    firstLineHeading: 'a web developer',
    secondLineHeading: ['with a','for' ],
    span:['passion', '.'],
    thirdLineHeading: 'communication',
    bodyCopy: 'A bigger picture person who knows the devil is in the details. What do I offer? Products true to your visions, where the focus always lies in the user experience. With a broad interest in colors, typography, psychology, philosophy and technology, my goal is to make that experience as positive as possible.'





}