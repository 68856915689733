import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const ColorSectionContainer = styled.div` 
 background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgOrange : props.theme.yerbaYellow)};
color: ${( props ) => (props.mode === 'pepp' ? props.theme.peppTextWhite : props.theme.textBlack)}; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

`
export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgOrange : props.theme.yerbaYellow)};
  color: ${({ theme }) => theme.textBlack};
  ${Container}
`;

export const ColorHeader = styled.h1`
  font-weight: 900;
  line-height: 4rem;
  padding-bottom: 2rem;
  padding-top: 6rem;
  text-align: center;
  letter-spacing: -0.03em;
  span {
    color: ${({ theme }) => theme.textWhite};
  }

  ${breakpoints.s} {
    font-size: 3.5rem;
  }

  ${breakpoints.m} {
    font-size: 4rem;
  }

  ${breakpoints.l} {
    font-size: 4rem;
  }

  ${breakpoints.tablet} {
    font-size: 4rem;
  }

  ${breakpoints.laptop} {
    font-size: 6rem;
  }

  ${breakpoints.desktop} {
  }
`;

export const ColorPaletteContainer = styled.div`
  display: grid;
  padding-top: 2rem;
  padding-bottom: 4rem;

  ${breakpoints.s} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  ${breakpoints.m} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }

  ${breakpoints.l} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.8rem;
  }
  ${breakpoints.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
  ${breakpoints.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }

  div {
    background: #5fa67c;
    border-radius: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.15);

    ${breakpoints.s} {
      width: 8em;
      height: 8em;
    }

    ${breakpoints.m} {
      width: 9em;
      height: 9em;
    }

    ${breakpoints.l} {
      width: 10em;
      height: 10em;
    }

    ${breakpoints.tablet} {
    }

    ${breakpoints.laptop} {
      width: 12em;
      height: 12em;
    }

    ${breakpoints.desktop} {
      width: 13em;
      height: 13em;
    }
  }

  div:nth-of-type(1) {
    /* background: #5FA67C; */
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.pfirst : props.theme.first)};
  }
  div:nth-of-type(2) {
    /* background:#3D7353; */
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.psecond : props.theme.second)};
  }
  div:nth-of-type(3) {
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.pthird : props.theme.third)};
    /* background:#EA7167; */
  }
  div:nth-of-type(4) {
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.pfourth : props.theme.fourth)};
 
  }
  div:nth-of-type(5) {
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.pfifth : props.theme.fifth)};
  
  }
  div:nth-of-type(6) {
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.psixth : props.theme.sixth)};
 
  }
  div:nth-of-type(7) {
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.pseventh : props.theme.seventh)};
 
  }
  div:nth-of-type(8) {
    background: ${( props ) => (props.mode === 'pepp' ? props.theme.peighth : props.theme.eighth)};

  }
`;
