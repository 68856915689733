import React, { useState, useEffect } from "react";
import sanityClient from "../../client";
import { motion } from "framer-motion";
import {
  Header,
  ProjectHeaderSectionContainer,
  PageContainer,
} from "./ProjectHeaderSection.styles";
// import mateHeader from "../../assets/img/elmatedorado-header.svg";
// import peppHeader from "../../assets/img/pepposvettheader.svg";

const ProjectHeaderSection = ({mode}) => {
  const [postData, setPostData] = useState("");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type  == "project"]{
          logoImg{
              asset->{
                  _id,
                  url
              },
          }
  }`
      )
      .then((data) => setPostData(data))
      .catch(console.error);
  }, []);



  let yerbaLogo = postData[0]?.logoImg.asset.url
  let peppLogo = postData[1]?.logoImg.asset.url
  

  return (
    <div>
        <ProjectHeaderSectionContainer mode={mode}>
      <PageContainer mode={mode}>
          <Header mode={mode}
          as={motion.header} 
          initial={{ opacity: 0}}
          whileInView={{ opacity: 10}}
          transition={{ duration: 3}}
          viewport={{ once: true }}
          
          >
            {mode === "pepp" ? (
              <img src={peppLogo} alt="" />
            ) : (
              <img src={yerbaLogo} alt="" />
            )}
          </Header>
      </PageContainer>
        </ProjectHeaderSectionContainer>
    </div>
  );
};

export default ProjectHeaderSection;
