export const lightTheme = {
  primary: "#695595",
  primaryDarker: "#534375",
  primaryLighter: "#8367C7",
  accent: "#F29C5D",
  textWhite: "#F8F8F8",
  textBlack: "#272727",
  bgWhite: "#F8F8F8",
  uxBgWhite:"#F8F8F8",
  navBarBg:'#695595',
  linkActive: "#F29C5D",

  yerbaBgGreen: "#5FA67C",
  yerbaYellow: "#F3C359",
  yerbaAccentGreen: "#5FA67C",

  // color section yerba
  // ----------------------------
  first: "#5FA67C",
  second: "#3D7353",
  third: "#EA7167",
  fourth: "#F8D22A",
  fifth: "#F3C359",
  sixth: "#FFFFFF",
  seventh: "#484848",
  eighth: "#9A9A9A",

  // -----------------------------
  peppBgBlue: "#303963",
  peppBgOrange: "#F9A343",
  peppBgWhite: "#FFFF",
  peppAccentOrange: "#F79824",
  peppTextWhite: "#FFFF",

  // color section pepp
  // ----------------------------
  pfirst: "#F9A343",
  psecond: "#FFAA00",
  pthird: "#D3882C",
  pfourth: "#FFFFFF",
  pfifth: "#303963",
  psixth: "#DADADA",
  pseventh: "#000000",
  peighth: "#EA7167",

  // -----------------------------
};

export const darkTheme = {
  primary: "#1E1E1E",
  primaryDarker: "#161616",
  primaryLighter: "#353535",
  accent: "#FFFF",
  textWhite: "#C2F8CB",
  textBlack: "#FFFF",
  bgWhite: "#1E1E1E",
  uxBgWhite:"#1E1E1E",
  navBarBg:"#1A1A1A",
  linkActive: "#FFFF",

  yerbaBgGreen: "#1E1E1E",
  yerbaYellow: "#1E1E1E",
  yerbaAccentGreen: "#C2F8CB",

  // color section yerba
  // ----------------------------
  first: "#5FA67C",
  second: "#3D7353",
  third: "#EA7167",
  fourth: "#F8D22A",
  fifth: "#F3C359",
  sixth: "#FFFFFF",
  seventh: "#484848",
  eighth: "#9A9A9A",

  peppBgBlue: "#1E1E1E",
  peppBgOrange: "#1E1E1E",
  peppBgWhite: "#1E1E1E",

  peppAccentOrange: "#C2F8CB",
  peppTextWhite: "#FFFF",

    // color section pepp
  // ----------------------------
  pfirst: "#F9A343",
  psecond: "#FFAA00",
  pthird: "#D3882C",
  pfourth: "#FFFFFF",
  pfifth: "#303963",
  psixth: "#DADADA",
  pseventh: "#000000",
  peighth: "#EA7167",

  // -----------------------------
};

export const breakpoints = {
  // xs: '@media(min-width: 0px)',
  s: "@media(min-width: 0px)",
  m: "@media(min-width: 375px)",
  l: "@media(min-width: 425px)",
  tablet: "@media(min-width: 768px)",
  tabletLarge: "@media(min-width: 960px)",
  laptop: "@media(min-width: 1024px)",
  laptopLarge: "@media(min-width: 1200px)",
  desktop: "@media(min-width: 1440px) ",
};


