import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ theme }) => theme.textBlack};
  ${Container}
`;

export const BackgroundSectionContainer = styled.div`
 background: ${( props ) => (props.mode === 'pepp' ? props.theme.peppBgOrange : props.theme.yerbaYellow)};
 `;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.laptop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  ${breakpoints.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextContainer = styled.div`
  h1 {
    font-weight: 900;
    line-height: 4rem;
    padding-bottom: 2rem;
    padding-top: 6rem;
    margin: 0 auto;
    letter-spacing: -0.03em;

    ${breakpoints.s} {
      font-size: 3.5rem;
      line-height: 2.5rem;

 
    }
    ${breakpoints.m} {
      font-size: 3.5rem;
    }
    ${breakpoints.l} {
      font-size: 4rem;
      line-height: 3rem;
    }

    ${breakpoints.tablet} {
      font-size: 4rem;
      line-height: 2.5rem;
    }


    ${breakpoints.laptop} {
      padding-top: 10rem;
      font-size:6rem;
      line-height: 3.5rem;
    }
    ${breakpoints.desktop} {
      padding-top: 18rem;
    }
  }

  span {
    color: ${({ theme }) => theme.textWhite};
  }

  p {
    padding-bottom: 2rem;
    line-height:160%;
    ${breakpoints.s} {
      padding-top:1rem;
      font-size:1.2rem;
    }
    ${breakpoints.m} {
    }
    ${breakpoints.l} {
    }

    ${breakpoints.tablet} {
      width: 490px;
    }

    ${breakpoints.laptop} {
        
    }
    ${breakpoints.desktop} {
        width: 490px;
    }
  }
`;


export const SecondTextContainer = styled.div`
  grid-area: text;
  
  h2 {
    font-weight: 700;
    font-style:bold;
    letter-spacing: -0.03em;
    font-size:1.2rem;
    padding-bottom:1rem;

    ${breakpoints.s} {
   
    }
    ${breakpoints.m} {
      
    }
    ${breakpoints.l} {
        padding-top: 0rem;
    }

    ${breakpoints.tablet} {
      font-size:1.2rem;
    }

    ${breakpoints.laptop} {
      padding-top: 10rem;
      margin-left:0rem;
      
    }
    ${breakpoints.desktop} {
      padding-top: 15rem;
     
    }
  }


  p {
    line-height:160%;
    margin-bottom:2rem;
    padding-bottom: 2rem;
    ${breakpoints.s} {
      font-size:1.2rem;
    }
    ${breakpoints.m} {
    }
    ${breakpoints.l} {
    width:100%;
  
    
    }
    ${breakpoints.tablet} {
    width:65%;
    }

    ${breakpoints.laptop} {
        padding-right:8rem;
        width:100%;
    }
    ${breakpoints.desktop} {
        padding-right:12rem;
    }
  }
`;




export const ImageWrapper = styled.div`
  display: flex;

  img {
    width: 100%;
    object-fit: contain;
    padding-left: 1rem;
    padding-bottom: 2rem;
    justify-content: center;

    ${breakpoints.l} {
      width: 60%;
      margin: 0 auto;
    }

    ${breakpoints.tablet} {
      width: 50%;
      margin: 0 auto;
    }

    ${breakpoints.laptop} {
      width: 50%;
      padding-top: 6rem;
      margin-left: 12rem;
    }

    ${breakpoints.desktop} {
      width: 50%;
      padding-top: 6rem;
      margin-left: 12rem;
    }
  }
`;

export const SecondImageWrapper = styled.div`
  display: flex;
  grid-area: img;
  img {
    width: 100%;
    object-fit: contain;
    padding-left: 1rem;
    padding-bottom: 7rem;
    justify-content: center;
    

    ${breakpoints.s} {
      margin-bottom:4rem;
      padding-bottom: 0rem;
    }

    ${breakpoints.l} {
      width: 60%;
      margin: 0 auto;
      margin-bottom:3rem;
    }

    ${breakpoints.tablet} {
      width: 50%;
      margin:0 auto;
      margin-bottom:3rem;
    }

    ${breakpoints.laptop} {
      width: 50%;
      padding-top: 0rem;
      margin-right: 12rem;
    }

    ${breakpoints.desktop} {
      width: 50%;
      padding-top: 6rem;
      margin-right: 12rem;
    }
  }
`;

export const SecondItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;

  ${breakpoints.s} {
    padding-top: 0;
  }

  ${breakpoints.laptop} {
    display: grid;
    grid-template-areas: "img text";
  }
  ${breakpoints.desktop} {
    padding-top: 0;
  }


`;
