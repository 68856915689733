import { useScrollTo } from "../../hooks/useScrollTo";
import { Helmet, HelmetProvider } from "react-helmet-async";

import {
  HeaderSection,
  ProjectSection,
  TechSection,
  AboutSection,
  ContactSection,
} from "../../components";

const Home = (props) => {
  const { theme } = props;

  useScrollTo();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Yofal | Home </title>
          <meta name="description" content="yofal portfolio" />
        </Helmet>
        <HeaderSection />
        <ProjectSection theme={theme} />
        <AboutSection />
        <TechSection />
        <ContactSection />
      </HelmetProvider>
    </>
  );
};

export default Home;
