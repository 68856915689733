import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
/* CSS-reset and global styles */

*{
    box-sizing: border-box;
    margin:0;
    padding:0;
    font-family: 'Montserrat', sans-serif;    
}


`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1440px;
  padding-right: 4.5em;
  padding-left: 4.5em;
  margin: 0 auto;


  @media screen and (max-width: 425px) {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
`;

export default GlobalStyle;
