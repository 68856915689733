export const yerbaText = {
    subHeadingOne:'Bringing Argentina to your doorstep',
    bodyOne: 'Yerba mate is a traditional drink from Argentina whose richness in both taste and caffeine has become widely popular in South America. Even though the drink is not yet well known in Europe, football players like Lionel Messi drinking it has made people more curious about this alternative to coffee.',
    bodyTwo: 'For the curious it is now easier than ever to try the variety of different brands of yerba mate. With El Mate Dorado the goal is to make this possible with just a few clicks from home.'
}

export const peppText = {
    subHeadingOne:'A place for people to grow',
    bodyOne: 'Lifting weights is no longer only reserved for bodybuilders and athletes, but for people of any walks of life. Therefore, the search for places that meet this demand has skyrocketed in the last decade. ',
    bodyTwo: 'A gym should be a place for anyone who wants to better themselves, no matter their background. Pepp & Svett welcomes you to sign up online so you can begin that journey today. Whether you are a beginner getting started or someone experienced having reached a plateau, Pepp & Svett helps you overcome any obstacles that keep you from growing.'
}

