import styled from "styled-components/macro";
import { Container } from "../../globalStyle";
import { breakpoints } from "../../Themes";

export const ContactSectionContainer = styled.section`
  background: ${({ theme }) => theme.primary};
  width: 100%;
  position: relative;
  padding-bottom: 6rem;
  padding-top: 12rem;
  height:100vh;
  ${breakpoints.tablet} {
    padding-top:0;

  }
`;

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-right: 3em;
    padding-left: 3em;
  }

  @media screen and (min-width: 280px) and (max-width: 767px) {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  ${Container}
`;

export const ContactContainer = styled.div`
  color: ${({ theme }) => theme.textWhite};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 4rem;

  padding-top: 4rem;
  padding-bottom: 2rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  ${breakpoints.tablet} {
    height:100vh;
    padding-top:0;

  }

  p:nth-of-type(1) {
    margin-bottom: 0rem;
  }

  p:nth-of-type(2) {
  
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: 400;
    position:absolute;
    bottom:2rem;
  }

  @media screen and (max-width: 960px) {
    padding: 1em;
  }
  @media screen and (max-width: 780px) {
    font-size: 2rem;
  }
  @media screen and (max-width: 320px) {
    font-size: 1.5rem;
  }
`;
